<template>
  <div style="width:100%;display:flex;justify-content: space-between;">
    <div class="line-content">
      <div class="flex al-center m-b1">
        <span class="titleIcon"><img style="width:20px;height:20px" src="../../../assets/images/dataCenter/u179.png" alt=""></span>
        <span class="title-style">线路统计</span>
        <span style="margin-left:10px">共<span style="color:#0066CC;margin:0 5px">{{ pagination.total }}</span>条线路</span>
      </div>
      <div>
        <a-form :model="searchForm" layout="inline">
          <a-form-item label="">
            <a-cascader style="width:150px" :options="$store.state.app.PClist" v-model:value="startData" :show-search="{ filter }"
              :changeOnSelect="true" @change="setStartAddress" placeholder="起点" allowClear />
          </a-form-item>
          <a-form-item label="">
            <a-cascader style="width:150px" :options="$store.state.app.PClist" v-model:value="endData" :show-search="{ filter }"
              :changeOnSelect="true" @change="setEndAddress" placeholder="终点" allowClear />
          </a-form-item>
          <a-form-item label="">
          <a-select v-model:value="searchForm.timePeriodType"  placeholder="全部">
            <a-select-option :value="1">全部</a-select-option>
            <a-select-option :value="2">一年</a-select-option>
            <a-select-option :value="3">三个月</a-select-option>
          </a-select>
        </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.sortParam" placeholder="排名类型">
              <a-select-option :value="1">总次数</a-select-option>
              <a-select-option :value="2">超时次数</a-select-option>
              <a-select-option :value="3">准时率</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
          <a-select v-model:value="searchForm.sort" style="width:80px" placeholder="降序">
            <a-select-option :value="1">降序</a-select-option>
            <a-select-option :value="2">升序</a-select-option>
          </a-select>
        </a-form-item>
          <a-form-item label="">
            <a-space>
              <a-button  type="primary" @click="searchData">
                查询
              </a-button>
              <a-button @click="resetData"> 重置 </a-button>
            </a-space>
          </a-form-item>
        </a-form>
        <div class="content-data">
          <div class="data-div" v-for="(item, index) in listData" :key="index">
            <div class="rankNum"  >{{ item.rankNum }}
              <div class="rankNumBack"><img src="../../../assets/images/dataCenter/topBackImg.png" alt=""></div>
            </div>
            <div style="width:100%">
              <div class="data-city">
                <span style="font-weight: bold;font-size: 16px;">{{ item.startCity }}-{{ item.endCity }}</span>
                【<span>总次数：{{ item.totalNum }}次</span><span style="margin:0 15px">超时次数：{{ item.timeoutNum
                  }}次</span><span>准时率：{{ item.onTimeRate }}%</span>】
              </div>
              <div class="data-show">
                <div style="width:35%">
                  <div>价格区间：{{ item.minTransFee }}-{{ item.maxTransFee }}</div>
                  <div>最新承运：{{ item.carrierName }}</div>
                </div>
                <div style="width:30%">
                  <div>平均价格：￥{{ item.avgTransFee }}</div>
                  <div>最新价格：{{ item.latestTransFee }}</div>
                </div>
                <div style="width:32%">
                  <div>最新时效：{{ item.time }}小时</div>
                  <div>最新时间：{{ item.latestTime }}</div>
                </div>
                <div style="position:absolute;right:0px;bottom:0px;">
                  <a-button type="primary" v-if="index == lookIdxBtn"   size="small"  @click="lookLineData(item,index)">查看明细</a-button>
                  <a-button type="primary" v-else   size="small" ghost @click="lookLineData(item,index)">查看明细</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div class="m-t2 flex ju-end">
      <a-pagination size="small" :total="pagination.total" @showSizeChange="showSizeChange" :show-total="pagination.showTotal" v-model:current="pagination.current"
        v-model:pageSize="pagination.pageSize" show-size-changer show-quick-jumper @change="linePageChange" />
     </div>
    </div>
    <div class="detail-content" v-show="listData.length > 0">
    <div class="loading-div" v-show="loading">
      <a-spin size="large" />
    </div>
      <!-- 前5排名 -->
      <div>
        <div class="flex al-center m-b1">
          <span class="titleIcon"><img style="width:20px;height:20px" src="../../../assets/images/dataCenter/u173.png" alt=""></span>
          <span class="title-style">承运商</span>
        </div>
        <div>
          <repPbcNav ref="repPbcNavRef" @resetBtn="resetBtn" @searchAdd="searchAdd" :iptData="iptData"></repPbcNav>
        </div>
        <div style="font-weight: bold;">
         <span> 总发运次数<span style="color:#0066CC;margin:0 4px">{{calculate.forwardingNum}}</span> 次</span> 
         <span class="m-l4">总超时次数 <span style="color:#0066CC;margin:0 4px">{{ calculate.overtimeNum }}</span> 次</span>
         <span  class="m-l4">准时率 <span style="color:#0066CC;margin:0 4px">{{  calculate.punctuality}}%</span></span>
        </div>
        <div class="data-div" v-for="item in cityLineDetail" :key="item.carrierId">
          <div style="font-size: 16px;">
            <span style="font-weight: bold;color:#0066CC">TOP{{item.rankNum}}</span>
            <span style="margin: 0 10px">{{item.carrierName}}</span>
            <a-rate v-model:value="item.carrierLevel" :disabled="true" />
          </div>
          <div style="display:flex;justify-content: space-between;font-size: 12px;color:#6b778c">
            <div style="width:25%">
              <div>发运次数：{{item.totalNum}}次</div>
              <div>平均价格：￥{{item.avgTransFee}}</div>
            </div>
            <div style="width:25%">
              <div>超时次数：{{item.timeoutNum}}次</div>
              <div>最新价格：￥{{item.latestTransFee}}</div>
            </div>
            <div style="width:25%">
              <div>准时率：{{item.onTimeRate}}%</div>
              <div>最新时效：{{item.time}}</div>
            </div>
            <div style="width:25%">
              <div>价格区间：{{item.minTransFee}}-{{item.maxTransFee}}</div>
              <div>最新时间：{{item.latestTime}}</div>
            </div>
          </div>
        </div>
        <div v-if="cityLineDetail.length == 0&&!loading">
            <a-empty :image="simpleImage" />
        </div>
        <div class="m-t2 flex ju-end">
          <a-pagination size="small" :total="carrierPagination.total" v-model:current="carrierPagination.current" @showSizeChange="carrierShowSizeChange"
        v-model:pageSize="carrierPagination.pageSize" :show-total="carrierPagination.showTotal" show-size-changer show-quick-jumper @change="carrierPageChange" />
        </div>
      </div>
      <!-- 时效走势 -->
      <!-- <div style="width:100%;flex:1">
        <div id="main" style="width:100%;height:100%"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import * as echarts from 'echarts'
import { lineStatisticsData,cityLineData } from '@/api/carrier/carrierList'
import repPbcNav from '@/views/repository/components/repPbcNav'
import { getOrgTreeList } from "@/api/global";
import {transitionTimeYMD,compareTime } from '@/utils/util.js'
import { message,Empty } from 'ant-design-vue';
import { size } from 'lodash-es'
export default {
   components: {
    repPbcNav
   },
  setup () {
    const iptData = ref([
      {
    type: "selectTime",
    placeholder: "开始时间",
    value: null,
    prop: "startTime",
    width: 120,
     },
     {
    type: "selectTime",
    placeholder: "结束时间",
    value: null,
    prop: "endTime",
    width: 120,
     },
     {
    type: "select",
    placeholder: "业务部门",
    value: null,
    prop: "business",
    width: 120,
    opt: [
      {
      id: 0,
      name: '华月'
      },
      {
      id: 1,
      name: '专线'
      },
    ],
     },
     {
    type: "select",
    placeholder: "调度部门",
    value: null,
    prop: "dispatch",
    width: 140,
    opt: [],
     },
     {
    type: "select",
    placeholder: "台量类型",
    value: null,
    prop: "type",
    width: 130,
    opt: [
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量'
      },
    ],
     },
     {
    type: "select",
    placeholder: "全部",
    value: 3,
    prop: "timer",
    width: 100,
    opt: [
      {
      id: 1,
      name: '全部'
      },
      {
      id: 2,
      name: '一年'
      },
      {
      id: 3,
      name: '三个月'
      },
    ],
     },
    ])
    const state = reactive({
      value: 2,
      loading: false,
      searchForm: {
        startCity: null,
        endCity: null,
        timePeriodType:3,
        sortParam: null,
        sort: null,
      },
      cityLineDetail: [],
      startData: [],
      endData: [],
      listData: [],
      mirrorSearchForm: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      calculate:{
        forwardingNum:'',
        overtimeNum:'',
        punctuality:''
      }
    })
    const lookIdxBtn  = ref(0)
    onMounted(() => {
      loadData(),
      getDepartment()
    })
    const loadData = () => {
      state.loading = true
      // setTimeout(() => {
      //   loadMtChart()
      // }, 1000)
      lineStatisticsData({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          if(state.listData.length > 0) lookLineData(state.listData[0],0)
        }
      }).finally(() => { state.loading = false })
    }
    const loadMtChart = () => {
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          text: 'Stacked Line'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          }
        ]
      };

      option && myChart.setOption(option);
    }
    const setStartAddress = (e, v) => {
      state.searchForm.startCity = e[1]
    }
    const setEndAddress = (e, v) => {
      state.searchForm.endCity = e[1]
    }
    const linePageChange = (e, v) => {
      state.pagination.current = e
      state.pagination.pageSize = v
      loadData()
    }
    const showSizeChange = (e,v) => {
      state.pagination.current = e
      state.pagination.pageSize = v
      loadData()
    }
    const iptTimePeriodType = ref(3)
    const searchData = () => {
      iptData.value[5].value = state.searchForm.timePeriodType
      iptTimePeriodType.value = state.searchForm.timePeriodType
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm.startCity = null
      state.searchForm.endCity = null
      state.searchForm.sortParam = null
      state.searchForm.sort = null
      state.searchForm.timePeriodType = 3
      state.startData = []
      state.endData = []
      searchData()
    }
   
    // 承运商搜索重置
    const resetBtn = () => {
      iptData.value[5].value = 3
      iptTimePeriodType.value = 3 
      carrierPagination.value.current = 1
      remakeSearch.value  = {}
      reqCityLineData(lineData.value,lookIdxBtn)
    }
    const carrierPagination = ref( {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      })
    const lineData = ref({}) //记录查看哪一项
    const carrierPageChange = (e,v) => {
      carrierPagination.value.current = e
      carrierPagination.value.pageSize = v
      reqCityLineData(lineData.value)
    }
    const carrierShowSizeChange = (e,v) => {
      carrierPagination.value.current = e
      carrierPagination.value.pageSize = v
      reqCityLineData(lineData.value)
    }
    const remakeSearch = ref({})
    const lookLineData = (item,index) => {
      carrierPagination.value.current = 1
      lookIdxBtn.value = index
      lineData.value = item
      reqCityLineData(item, remakeSearch.value )
    }
    //获取搜索的信息
    const repPbcNavRef = ref(null)
    const searchAdd = (val) => {
      if(compareTime(val.startTime,val.endTime)){
          message.warn('结束时间必须大于开始时间，请重新选择')
          repPbcNavRef.value.resetTime()
          return
       }
       iptTimePeriodType.value = val.timer
      let business =  getMsg(val.business,iptData.value[2].opt)
      let type =  getMsg(val.type,iptData.value[4].opt)
      let msg = {
        startTime:transitionTimeYMD(val.startTime),
        endTime:transitionTimeYMD(val.endTime),
        orderBusiness:business,
        orgId:val.dispatch,
        vehicleNum:type
      }
      remakeSearch.value = msg
      reqCityLineData(lineData.value,msg)
    }
    const reqCityLineData = (val,msg={}) => {
      state.loading = true
      let arr = {
        startCity:val.startCityId,
        endCity:val.endCityId,
        current:carrierPagination.value.current,
        size:carrierPagination.value.pageSize,
        timePeriodType:iptTimePeriodType.value
      }
      Object.assign(arr,msg)
      cityLineData(arr).then(res=>{
        if(res.code === 10000){
          dispatchDataFun(res.data.records)
          console.log( res.data.records);
          state.cityLineDetail = res.data.records
          carrierPagination.value.total =  res.data.total
        }
      }).finally(()=>{
        state.loading = false
      })
    }
    const dispatchDataFun = (data) => {
          let forwardingNum = 0
          let overtimeNum = 0
           data.forEach( item => {
            forwardingNum += item.totalNum
            overtimeNum += item.timeoutNum
           })
          
           state.calculate.forwardingNum = forwardingNum
           state.calculate.overtimeNum = overtimeNum
           state.calculate.punctuality = forwardingNum>0? ((forwardingNum - overtimeNum)*100/forwardingNum).toFixed(2) :0
    }
    //获取部门
    const getDepartment = () => {
      getOrgTreeList({}).then((res) => {
        if (res.code !== 10000) return;
        let treeList = res.data[0].treeList;
        iptData.value[3].opt = treeList;
      });
    };
    const getMsg = (id,msg=[],key='id') => {
      let obj = {
        label:'',
        value:''
      }
      msg.forEach(item => {
        if(item[key] == id){
          obj.label = item.name
          obj.value = item[key]
        }
      })
      return obj
    }
    return {
      ...toRefs(state),
      setEndAddress,
      lookLineData,
      setStartAddress,
      linePageChange,
      loadMtChart,
      searchData,
      resetData,
      lookIdxBtn,
      iptData,
      resetBtn,
      lineData,
      carrierPagination,
      carrierPageChange,
      showSizeChange,
      carrierShowSizeChange,
      searchAdd,
      repPbcNavRef,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
:deep(.navBox){
 padding: 0;
}
</style>
